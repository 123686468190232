export default function info(state = {}, action) {
  // debugger;
  switch (action.type) {
    case 'GETBASEINFO':
      return action.info;
    case 'SETBASEINFO':
      return {...state, ...action.info}
    default:
      return state;
  }
}
