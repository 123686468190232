const apis = {
  getPlatformByUrl: 'system/platformController/getPlatformByUrl',
  validateCode: 'system/systemFileController/validateCode',
  login: 'system/login',
  sendMsg: 'system/wechatController/sendMsg',
  loginPhone: 'system/loginPhone',
  checkAccountName: 'system/checkAccountName',
  checkmessage: 'system/forgetPassword/checkmessage',
  updatePwd: 'system/forgetPassword/updatePwd',
};

export default apis;
