import { useEffect, useState } from 'react';
import { Space } from 'antd';
import axios from '../../utils/axios';
import apis from './api';
import Account from './components/Account';
import Mobile from './components/Mobile';

const Login = () => {
  const [firstRender, setFirstRender] = useState(true);
  const [platformName, setPlatformName] = useState('');
  const [icp, setIcp] = useState('');
  const [type, setType] = useState(1);

  const changeType = () => {
    setType(type === 1 ? 2 : 1);
  };

  useEffect(() => {
    setFirstRender(false);
    if (!firstRender) {
      const getPlatform = async () => {
        let url = window.location.origin.includes('localhost')
          ? 'www.aaa.com'
          : window.location.origin;
        const { data } = await axios.post(apis.getPlatformByUrl, {
          pcUrl: url,
        });
        if (data.success) {
          sessionStorage.setItem('platformId', data.data.id);
        }
      };
      getPlatform();

      const origin = window.location.origin;
      if (origin.includes('dmpc')) {
        setPlatformName('承宝');
        setIcp('沪ICP备2021024619号-2');
        document.querySelector('title').innerText = '承宝灵活用工';
        document.querySelector('link[rel="icon"]').href = `${origin}/damai.ico`;
      } else if (origin.includes('llpc')) {
        setPlatformName('良联');
        setIcp('沪ICP备13007528号-2');
        document.querySelector('title').innerText = '良联';
        document.querySelector(
          'link[rel="icon"]'
        ).href = `${origin}/lianglian.ico`;
      } else if (origin.includes('zypc')) {
        setPlatformName('赞誉');
        setIcp('沪ICP备13007528号-2');
        document.querySelector('title').innerText = '赞誉';
        document.querySelector('link[rel="icon"]').href = `${origin}/zanyu.ico`;
      } else if (origin.includes('xry')) {
        setPlatformName('薪人云');
        setIcp('沪ICP备13007528号-2');
        document.querySelector('title').innerText = '薪人云';
        document.querySelector(
          'link[rel="icon"]'
        ).href = `${origin}/xinrenyun.ico`;
      } else {
        setPlatformName('久来米');
        setIcp('沪ICP备13007528号-2');
        document.querySelector('title').innerText = '久来米';
        document.querySelector(
          'link[rel="icon"]'
        ).href = `${origin}/favicon.ico`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return (
    <div className="Login">
      <div className="header">
        <h1>{platformName}</h1>
        <span>
          <b>中</b>简体中文
        </span>
      </div>

      <div className="outter">
        <div className="form-wrapper">
          <div className="pic"></div>
          <div className="form">
            <div className="title">
              {type === 1 ? '账号登录' : '手机号登录'}
            </div>

            <div className="change" onClick={changeType}>
              {type === 1 ? (
                <i className="icon-mobile"></i>
              ) : (
                <i className="icon-account"></i>
              )}
            </div>

            <div className="inner">{type === 1 ? <Account /> : <Mobile />}</div>
          </div>
        </div>
      </div>

      <div className="footer">
        <Space>
          <span>{icp}</span>
          <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
            沪公网安备 31011502014561号
          </a>
        </Space>
      </div>
    </div>
  );
};
export default Login;
