import { message } from 'antd';
import axios from 'axios';
import qs from 'qs';

let contentType = 'application/x-www-form-urlencoded;charset=UTF-8';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers['Content-Type'] = contentType;

axios.interceptors.request.use((config) => {
  // console.log('config', config);
  config.headers.common['token'] = sessionStorage.getItem('token');
  config.headers.common['platformId'] = sessionStorage.getItem('platformId');
  if (
    config.headers['Content-Type'] === contentType &&
    config.method === 'post'
  ) {
    config.data = qs.stringify(config.data);
  }
  return config;
});

axios.interceptors.response.use(
  (res) => {
    const { success, rtnMessage, rtnCode } = res.data;
    if (rtnCode === -9999) {
      message.error({
        content: rtnMessage,
        onClose: () => {
          sessionStorage.clear();
          window.location.href = '/';
        },
      });
    }
    if (res.data instanceof Blob) {
      return res;
    }
    if (success) {
      return res;
    } else {
      rtnMessage && message.error(rtnMessage);
      // return Promise.reject(res);
      return res;
    }
  },
  (err) => {}
);

export default axios;
