import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  message,
  Space,
  Statistic,
  Typography,
} from 'antd';
import axios from '../../utils/axios';
import apis from './api';

const { Title } = Typography;
const { Countdown } = Statistic;

const Reset = () => {
  const navigate = useNavigate();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [step, setStep] = useState(1);
  const [countdown, setCountdown] = useState(false);
  const [seconds, setSeconds] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const rules = [
    {
      required: true,
      message: '',
    },
  ];

  const handleForward = () => {
    navigate(-1);
  };

  const handleNext = () => {
    form1
      .validateFields()
      .then(async (values) => {
        checkAccountName(values);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkAccountName = async (values) => {
    let postData = {
      ...values,
      platformId: sessionStorage.getItem('platformId'),
    };
    const { data } = await axios.post(apis.checkAccountName, postData);
    if (data.success) {
      form2.setFieldValue('phone', postData.accountName);
      setStep(2);
    }
  };

  const onFinish = () => {
    console.log('fin');
    setCountdown(false);
  };

  const getCode = async () => {
    let postData = {
      type: 6,
      phone: form2.getFieldValue('phone'),
    };
    const { data } = await axios.post(apis.sendMsg, postData);
    if (data.success) {
      setCountdown(true);
      setSeconds(Date.now() + 60 * 1000);
    }
  };

  const handleVerify = () => {
    form2
      .validateFields()
      .then(async (values) => {
        checkMessage(values);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkMessage = async (values) => {
    let postData = {
      ...values,
      platformId: sessionStorage.getItem('platformId'),
    };
    const { data } = await axios.post(apis.checkmessage, postData);
    if (data.success) {
      form3.setFieldValue('accountName', postData.phone);
      setStep(3);
    }
  };

  const handleSubmit = () => {
    form3
      .validateFields()
      .then(async (values) => {
        updatePassword();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updatePassword = async () => {
    setDisabled(true);
    let postData = {
      ...form3.getFieldsValue(true),
    };
    const { data } = await axios.post(apis.updatePwd, postData);
    if (data.success) {
      message.success({
        content: '修改成功',
        onClose: () => {
          handleForward();
        },
      });
    } else {
      setDisabled(false);
    }
  };

  return (
    <div className="Reset clearfix">
      <Title>找回密码</Title>
      <div className="form">
        {step === 1 && (
          <Form form={form1} name="form1" requiredMark={false} size="large">
            <Form.Item label="" name="accountName" rules={rules}>
              <Input
                placeholder="请输入账号/手机号"
                maxLength={11}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item>
              <Space size="large">
                <Button onClick={handleForward}>返回首页</Button>
                <Button type="primary" onClick={handleNext}>
                  下一步
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )}

        {step === 2 && (
          <Form form={form2} name="form2" requiredMark={false}>
            <Form.Item label="" name="phone">
              <Input disabled />
            </Form.Item>
            <Form.Item className="code">
              <Form.Item label="" name="code" rules={rules}>
                <Input
                  maxLength={4}
                  placeholder="请输入验证码"
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {!countdown ? (
                  <Button type="link" size="small" onClick={getCode}>
                    获取验证码
                  </Button>
                ) : (
                  <Countdown
                    value={seconds}
                    format="s 秒后可重发"
                    onFinish={onFinish}
                  />
                )}
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Space size="large">
                <Button onClick={handleForward}>返回首页</Button>
                <Button type="primary" onClick={handleVerify}>
                  立即验证
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )}

        {step === 3 && (
          <Form form={form3} name="form3" requiredMark={false}>
            <Form.Item
              label=""
              name="newPwd"
              rules={[
                { required: true, whitespace: true, message: '' },
                {
                  pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d#?!@$%^&*-]{6,20}$/,
                  message: '密码长度需6-20位，数字+字母组合',
                },
              ]}
            >
              <Input.Password
                placeholder="请输入新密码"
                autoComplete="new-password"
                maxLength={20}
              />
            </Form.Item>
            <Form.Item
              label=""
              name="againPwd"
              dependencies={['newPwd']}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: '',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPwd') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('两次输入密码不一致'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="请输入确认密码" maxLength={20} />
            </Form.Item>
            <Form.Item>
              <Space size="large">
                <Button onClick={handleForward}>返回首页</Button>
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  disabled={disabled}
                >
                  保存提交
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};
export default Reset;
