import { useState } from 'react';
import { Button, Form, Input, message, Statistic } from 'antd';
import axios from '../../../utils/axios';
import apis from '../api';

const { Countdown } = Statistic;

const Mobile = () => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(false);
  const [countdown, setCountdown] = useState(false);
  const [seconds, setSeconds] = useState(null);

  const rules = [
    {
      required: true,
      message: '',
    },
  ];

  const getCode = async () => {
    let postData = {
      type: 5,
      phone: form.getFieldValue('phone'),
    };
    const { data } = await axios.post(apis.sendMsg, postData);
    if (data.success) {
      setCountdown(true);
      setSeconds(Date.now() + 60 * 1000);
    }
  };

  const onFinish = () => {
    console.log('fin');
    setCountdown(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        handleLogin(values);
      })
      .catch((error) => {
        console.log(error);
        message.error('请完善登录信息');
      });
  };

  const handleLogin = async ({ phone, code }) => {
    setDisabled(true);
    const { data } = await axios.post(apis.loginPhone, {
      phone,
      code,
    });
    if (data.success) {
      if (Object.keys(data.data).length) {
        const { token, account } = data.data;
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('initPassworkMark', account.initPassworkMark);
        window.location.reload();
      } else {
        message.error(data.rtnMessage);
      }
    }
    setDisabled(false);
  };

  return (
    <Form form={form} name="mobile" layout="vertical" requiredMark={false}>
      <Form.Item label="" name="phone" rules={rules} className="mobile">
        <Input maxLength={11} autoComplete="off" placeholder="请输入手机号" />
      </Form.Item>

      <Form.Item className="code">
        <Form.Item label="" name="code" rules={rules}>
          <Input maxLength={4} autoComplete="off" placeholder="请输入验证码" />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <>
              {!countdown ? (
                <Button
                  type="link"
                  size="small"
                  disabled={
                    getFieldValue('phone') &&
                    getFieldValue('phone').length === 11
                      ? false
                      : true
                  }
                  onClick={getCode}
                >
                  获取验证码
                </Button>
              ) : (
                <Countdown
                  value={seconds}
                  format="s 秒后可重发"
                  onFinish={onFinish}
                />
              )}
            </>
          )}
        </Form.Item>
      </Form.Item>

      <Form.Item className="submit">
        <Button type="primary" disabled={disabled} onClick={handleSubmit}>
          登录
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Mobile;
