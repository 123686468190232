import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import info from './info';
import queryData from './queryData';

const reducers = combineReducers({ info, queryData });
const store = createStore(reducers, applyMiddleware(thunk));

store.subscribe(function () {
  // console.log('my store updated: ', store.getState());
  store.getState();
});

export default store;
