import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, message } from 'antd';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import axios from '../../../utils/axios';
import apis from '../api';

const Account = () => {
  const navigate = useNavigate();
  const [firstRender, setFirstRender] = useState(true);
  const [url, setUrl] = useState('');
  const [code, setCode] = useState('');
  const [disabled, setDisabled] = useState(false);

  const toReset = () => {
    navigate('reset');
  };

  const getCaptcha = async () => {
    const { data } = await axios.post(apis.validateCode);
    if (data.success) {
      let { base64, code } = data.data;
      setUrl(base64);
      setCode(code);
    }
  };

  const onFinish = (values) => {
    // console.log('Success:', values);
    let { captcha } = values;
    if (captcha.toUpperCase() === code) {
      handleLogin(values);
    } else {
      message.error('验证码错误');
    }
  };

  const handleLogin = async (obj) => {
    setDisabled(true);
    const { data } = await axios.post(apis.login, {
      accountName: obj.username,
      accountPwd: obj.password,
    });
    if (data.success) {
      const { token, account } = data.data;
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('initPassworkMark', account.initPassworkMark);
      // navigate('/welcome');
      window.location.reload();
    } else {
      setDisabled(false);
    }
  };

  useEffect(() => {
    setFirstRender(false);
    if (!firstRender) {
      getCaptcha();
    }
  }, [firstRender]);

  return (
    <Form
      name="basic"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
    >
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
        className="username"
      >
        <Input placeholder="请输入账号" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
      >
        <Input.Password
          iconRender={(visible) =>
            visible ? <EyeFilled /> : <EyeInvisibleFilled />
          }
          placeholder="请输入密码"
        />
      </Form.Item>

      <Form.Item className="captcha">
        <Form.Item
          label=""
          name="captcha"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input maxLength={4} autoComplete="off" placeholder="请输入验证码" />
        </Form.Item>
        <Form.Item noStyle>
          <img src={url} alt="" onClick={getCaptcha} />
        </Form.Item>
      </Form.Item>

      <div className="forget-pw">
        <Button type="link" size="small" onClick={toReset}>
          忘记密码
        </Button>
      </div>

      <Form.Item className="submit">
        <Button
          type="primary"
          htmlType="submit"
          disabled={disabled}
        >
          登录
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Account;
